@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can remove the following if you're not using it */
@layer base {
  html {
    @apply tracking-default;
  }
}
