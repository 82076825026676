@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body,
  #root {
    @apply font-mono tracking-default;
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: 'Roboto Mono', monospace;
}

.inter-font {
  font-family: 'Roboto Mono', monospace;
}

/* Rest of your existing styles */

.custom-scrollbar::-webkit-scrollbar {
  width: 8px; /* Add a default width */
}

/* Rest of your existing styles */
